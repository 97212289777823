import { combineReducers } from 'redux';
import user from './user';
import header from './header';
import subheader from './subheader';
import open_menu from './open_menu';
import sidebar from './sidebar';
import states from './states';

export const reducer = combineReducers({
    user,
    header,
    subheader,
    open_menu,
    sidebar,
    states
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;